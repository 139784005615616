<template>
  <div>
    <!-- 磐石加电简介 -->
    <el-row class="cc">
      <el-col :span="16" :offset="4">
        <p class="IntroductionTitle">磐石加电</p>
      </el-col>
      <el-col :span="16" :offset="4" class="sta">
        <p>“为行业应用赋能”是市场赋予磐石的使命，磐石致力于无限贴近用户需求，持续为用户创造价值。据此，磐石人面对纷繁复杂的市场需求，必须不断加电提升。本栏目为磐石内训资料汇总，同步对渠道体系开放，旨在通过构建开放的合作空间，与合作伙伴共同成长，共同为客户业务保驾护航。</p>
      </el-col>
    </el-row>
    <!-- 视频列表 -->
    <el-row class="cw">
      <el-col :span="16" :offset="4" class="spa">
        <div class="fl" v-for="(item,index) in datas" :key="index">
          <div class="videoBox">
            <div class="cover" :class="'cover' + index" @click="checkVideo(item,index)">
              <img :src="window.ipConfig.baseUrl + item.image" />
            </div>
            <video :src="`https://www.service.navimentum.com:8893/upload/${item.videoName}`" preload="none" :class="'video' + index" controls controlslist="nodownload"></video>
          </div>
          <div class="pbox">
            <p class="spt">{{item.name}}</p>
            <p class="spcontent">{{item.content}}</p>
            <p class="sptimer">{{item.createTime | timeFilter}}</p>
          </div>
        </div>
        <div class="fl  fla flc" v-show="showSp">
          <div class="videoBox">
            <div class="cover">
              <img src="@/assets/psjd/fb.png" />
            </div>
            <video src="https://www.service.navimentum.com:8893/upload/pansh-1.mp4" preload="none" :class="'video' + index" controls controlslist="nodownload"></video>
          </div>
          <div class="pbox">
            <p class="spt">&emsp;</p>
            <p class="spcontent">&emsp;</p>
            <p class="sptimer">&emsp;</p>
          </div>
          <img src="@/assets/psjd/f6.png" class="flimg" />
        </div>
        <div class="fl fla" v-show="showMore" @click="getMore">
          <div class="videoBox">
            <div class="cover">
              <img src="@/assets/psjd/f4.png" />
            </div>
            <video src="https://www.service.navimentum.com:8893/upload/pansh-1.mp4" preload="none" :class="'video' + index" controls controlslist="nodownload"></video>
          </div>
          <div class="pbox">
            <p class="spt">&emsp;</p>
            <p class="spcontent">查看更多，请完成身份认证</p>
            <p class="sptimer">&emsp;</p>
            <!-- <p class="sptimer">{{item.createTime | timeFilter}}</p> -->
          </div>
        </div>
        <div class="clearfix"></div>
      </el-col>
    </el-row>
    <!-- 认证培训简介 -->
    <el-row class="cc">
      <el-col :span="16" :offset="4">
        <p class="IntroductionTitle">认证培训</p>
      </el-col>
      <el-col :span="16" :offset="4" class="sta">
        <p>磐石坚持交付的是服务而不是产品本身，磐石一直在为构建完善的服务网络而不懈努力，认证培训栏目面向全域合作伙伴提供全方位认证培训服务信息，合作伙伴可通过本模块提出培训或认证考试申请，磐石认证中心统一受理后会及时与合作伙伴取得联系。</p>
        <el-button class="sub" type="primary" @click="dialogRegVisible = true">提交申请</el-button>
      </el-col>
    </el-row>
    <!-- 视频申请弹出框 -->
    <el-dialog
      title="请输入您的企业信息"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose">
      <div>
        <el-form :model="rzpxForm" label-position="left" ref="rzpxForm" :rules="rzpxRules" class="rzpx" label-width="100px">
          <el-form-item label="企业名称" prop="company">
            <el-input v-model="rzpxForm.company" clearable placeholder="企业名称"></el-input>
          </el-form-item>
          <el-form-item label="联系人手机" prop="tel">
            <el-input v-model="rzpxForm.tel" clearable placeholder="请输入手机号码"></el-input>
          </el-form-item>
          <!-- <el-form-item label="邮箱" prop="tel">
            <el-input v-model="rzpxForm.email" clearable placeholder="请输入联系邮箱"></el-input>
          </el-form-item> -->
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="submitPoc('rzpxForm')">提 交</el-button>
      </span>
    </el-dialog>
    <!-- 认证申请弹出框 -->
    <el-dialog
      title="认证培训申请"
      :visible.sync="dialogRegVisible"
      width="50%"
      :before-close="handleRegClose">
      <div>
        <el-form :model="subForm" label-position="left" ref="subForm" :rules="subRules" class="rzpxa" label-width="110px">
          <el-row :gutter="10">
            <el-col :span="24" class="conte contea">
              <!-- 内容 -->
              <el-form-item label="申请内容">
                <el-checkbox-group v-model="subForm.content">
                  <el-checkbox label="培 训"></el-checkbox>
                  <el-checkbox label="考 试"></el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-col>
            <el-col :span="24" class="tit">企业信息</el-col>
            <el-col :span="12" class="contea">
              <el-form-item label="企业名称" prop="company">
                <el-input v-model="subForm.company" clearable placeholder="请输入企业名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12" class="contea">
              <el-form-item label="业务区域" prop="region">
                <el-input v-model="subForm.region" clearable placeholder="请输入企业业务区域（省/市）"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24" class="contea">
              <el-form-item label="注册地址" prop="address">
                <el-input v-model="subForm.address" clearable placeholder="请输入企业注册地址"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24" class="tit">参加人员信息</el-col>
            <el-col :span="24">
              <div class="person" v-for="(item,index) in subForm.userInfoList" :key="index">
                <div class="fl">
                  <el-form-item 
                    label="参加人员姓名" 
                    :prop="'userInfoList.' + index + '.userName'"
                    :rules="{required: true, message: '请输入参与人员姓名', trigger: 'blur'}"
                    >
                    <el-input v-model="item.userName" clearable placeholder="请输入参与人员姓名"></el-input>
                  </el-form-item>
                </div>
                <div class="fl">
                  <el-form-item 
                    label="参加人员电话" 
                    :prop="'userInfoList.' + index + '.phone'"
                    :rules="[{required: true, message: '请输入参与人员电话', trigger: 'blur'},{ pattern:/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/, message: '请输入正确的手机号码', trigger: 'blur' }]"
                  >
                    <el-input v-model="item.phone" clearable placeholder="请输入参与人员电话"></el-input>
                  </el-form-item>
                </div>
                <div class="fl" v-show="index != subForm.userInfoList.length - 1">
                  <el-button 
                    size="mini" 
                    type="danger" 
                    circle
                    @click="removePerson(index)"
                    style="margin-left: 10px;margin-top: 5px;"
                  ><i class="el-icon-minus"></i></el-button>
                </div>
                <div class="fl" v-show="index == subForm.userInfoList.length - 1">
                  <el-button 
                    size="mini" 
                    type="success" 
                    circle
                    @click="subForm.userInfoList.push({userName:'',phone:''})"
                    style="margin-left: 10px;margin-top: 5px;"
                  ><i class="el-icon-plus"></i></el-button>
                </div>
                <div class="clearfix"></div>
              </div>

            </el-col>
            <el-col :span="24" class="tit">企业联系人</el-col>
            <el-col :span="12" class="contea">
              <el-form-item label="联系人姓名" prop="name">
                <el-input v-model="subForm.name" clearable placeholder="请输入联系人姓名"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12" class="contea">
              <el-form-item label="联系人电话" prop="tel">
                <el-input v-model="subForm.tel" clearable placeholder="请输入联系人电话"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          
          
        </el-form>
      </div>
      <div class="dialog_foot">
        <el-button type="primary" @click="submit('subForm')">提 交</el-button>
        <span></span>
        <el-button @click="handleRegClose">取 消</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import {getVideo,postReg,RegCode,addRegForm,addVideoNum} from '@/api'
export default {
  name:'psjd',
  data(){

    // 表单手机号验证规则
    var reg = (rule, value, callback) =>{
      var regExp = new RegExp(/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/);
      if (regExp.test(value)) {
        callback()
      } else {
        callback(new Error('请输入正确的手机号码'));
      }
    }

    return {
      // 视频数据
      datas:[],
      // 视频申请弹出框显示
      dialogVisible:false,
      // 认证申请弹出框显示
      dialogRegVisible:false,
      // 视频申请表单
      rzpxForm:{
        // 企业名称
        company:'',
        // 手机号
        tel:'',
        // 邮箱(非必要)
        email:'',
      },
      // 表单验证规则 
      rzpxRules:{
        // 验证企业名称
        company:[
          {required: true, message: '请输入用户名称', trigger: 'blur'}
        ],
        // 验证手机号
        tel:[
          {required: true, message: '请输入电话号码', trigger: 'blur'},
          {validator:reg,trigger: 'blur'}
        ],
      },
      // 提交表单
      subForm:{
        // 内容
        content:[],
        // 企业名称
        company:'',
        // 注册地址
        region:'',
        // 业务地址
        address:'',
        // 参与人员列表(姓名,电话)
        userInfoList:[{userName:'',phone:''}],
        // 联系人姓名
        name:'',
        // 联系人电话
        tel:''
      },
      // 表单验证规则 
      subRules:{
        // 内容
        content:[
          {required: true, message: '请选择申请内容', trigger: 'blur'}
        ],
        // 企业
        company:[
          {required: true, message: '请输入您的企业名称', trigger: 'blur'}
        ],
        // 业务地址
        region:[
          {required: true, message: '请输入您的企业业务区域', trigger: 'blur'}
        ],
        // 注册地址
        address:[
          {required: true, message: '请输入您的企业注册地址', trigger: 'blur'}
        ],
        // 联系人姓名
        name:[
          {required: true, message: '请输入企业联系人姓名', trigger: 'blur'}
        ],
        // 联系人电话
        tel:[
          {required: true, message: '请输入企业联系人电话', trigger: 'blur'},
          {validator:reg,trigger: 'blur'}
        ],
        // 参与人员列表
        userInfoList: [
          { type: 'array', required: true, message: '请输入至少一个参与人员', trigger: 'change' }
        ],
      },
      // 更多框是否展示
      showMore:false,
      // 省略号是否展示
      showSp:false
    }
  },
  created(){
    // 延缓此接口,确保省略号框正常显示
    setTimeout(() => {
      this.getVideoData()
    },500)
    // 获取数据
    this.checkCode()
  },
  methods:{
    // 提交表单
    submit(Form){
      this.$refs[Form].validate(async (valid) => {
        if (valid) {
          let conte = ''
          if(this.subForm.content.length < 1){
            this.$message({message: '请至少选择一个申请内容',type: 'info'})
            return
          }
          // 整合申请内容
          if(this.subForm.content[1]){
            conte = this.subForm.content[0] +' ; ' + this.subForm.content[1]
          }else{
            conte = this.subForm.content[0]
          }
          this.subForm.content = conte
          // console.log(this.subForm)
          // 提交
          let result = await addRegForm(this.subForm)
          // console.log(result)
          if(result.code == 0){
            // 通过
            this.$message({message: '提交成功！请留意手机来电',type: 'success'})
            this.dialogRegVisible = false
            // 重置表单
            this.subForm = {content:[],company:'',region:'',address:'',userInfoList:[{userName:'',phone:''}],name:'',}
          }else{
            this.$message.error('提交失败!')
          }
        } else {
          this.$message.error('请正确填写内容，*为必填项')
          return false;
        }
      });
    },
    // 获取视频数据
    async getVideoData(){
      let result = await getVideo()
      // console.log(result)
      this.datas = result.data
      // 判断省略号框是否显示
      if(result.data.length == 3 && this.showMore){
        this.showSp = true
      }
    },
    // 表单删除对应参与人员
    removePerson(index){
      const arr = []
      // 遍历删除对应人员,根据index
      for (const key in this.subForm.userInfoList) {
        if (index != key) {
          arr.push(this.subForm.userInfoList[key])
        }
      }
      this.subForm.userInfoList = arr
    },
    // 播放视频
    playVideo(index){
      // 获取封面,移除
      var domSelect = document.getElementsByClassName('cover' + index)
      domSelect[0].remove()
      // 获取视频,播放
      var player = document.getElementsByClassName('video' + index)
      player[0].play()
    },
    // 用于页面挂载时审核验证码是否存在及有效
    async checkCode(){
      let code = localStorage.getItem('code')
      if(code){
        // 存在code
        let result = await RegCode(code)
        if(result.code == 0){
          // 撤销省略号与更多框
          this.showMore = false
        }else{
          // 撤销更多框
          this.showMore = true
          // 移除浏览器中的code
          localStorage.removeItem('code')
        }
      }else{
          // 撤销更多框
          this.showMore = true
      }
    },
    // 点击更多时
    getMore(){
      // 信息收集弹窗
      this.$prompt('', '请输入验证码', {
            // 区分关闭方式
            distinguishCancelAndClose: true,
            // 输入验证
            inputPattern: /^\d{6}$/,
            inputErrorMessage: '验证码格式不正确',
            confirmButtonText: '确 定',
            cancelButtonText: '提交认证申请',
          }).then(async ({ value }) => {
            // 验证码
            let result = await RegCode(value)
            if(result.code == 0){
              // 验证通过
              this.$message.success('验证成功')
              localStorage.setItem('code',value)
              this.showSp = false
              this.showMore = false

            }else if(result.code == 1){
              // 验证不通过
              this.$message.info('您的验证码已过期，请重新提交申请')
              // 清空code
              localStorage.removeItem('code')
              setTimeout(() => {
                this.dialogVisible = true
              },2000)
            }

          }).catch((e) => {
            // console.log(e)
            // 区分关闭方式
            // 取消输入
            if(e === 'cancel'){
              this.dialogVisible = true
            }
          });
    },
    // 审核验证码是否通过
    async checkVideo(item,index){
      let temp = {id:item.id,number:item.number}
      if(index <= 1){
        /* 前两个视频不做限制 */
        var domSelect = document.getElementsByClassName('cover' + index)
        domSelect[0].remove()
        var player = document.getElementsByClassName('video' + index)
        player[0].play()
        // 增加播放量
        addVideoNum(temp)
      }else{
        // 获取浏览器中的code
        let code = localStorage.getItem('code')
        if(code){
          // 如果code存在
          let result = await RegCode(code)
          if(result.code == 0){
            // this.$message.info('验证成功，即将开始播放')
            domSelect = document.getElementsByClassName('cover' + index)
            domSelect[0].remove()
            player = document.getElementsByClassName('video' + index)
            player[0].play()
            // 增加播放量
            addVideoNum(temp)
            // 撤销省略号与更多框
            this.showSp = false
            this.showMore = false

          }else if(result.code == 1){
            // 验证不通过
            this.$message.info('您的验证码已过期，请重新提交申请')
            localStorage.removeItem('code')
            setTimeout(() => {
              this.dialogVisible = true
            },2000)
          }

        }else{
          /* localstorage中无code */
          /* 弹框收集信息 */
          this.$prompt('', '请输入验证码', {
            distinguishCancelAndClose: true,
            inputPattern: /^\d{6}$/,
            inputErrorMessage: '验证码格式不正确',
            confirmButtonText: '确 定',
            cancelButtonText: '提交认证申请',
            // inputPattern: /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
            // inputErrorMessage: '邮箱格式不正确'
          }).then(async ({ value }) => {

            let result = await RegCode(value)
            if(result.code == 0){
              // 申请通过
              this.$message.success('验证成功，即将开始播放')
              // 播放视频
              var domSelect = document.getElementsByClassName('cover' + index)
              domSelect[0].remove()
              var player = document.getElementsByClassName('video' + index)
              player[0].play()
              // 增加播放量
              addVideoNum(temp)
              localStorage.setItem('code',value)
              // 撤销省略号与更多框
              this.showSp = false
              this.showMore = false

            }else if(result.code == 1){
              // 申请不通过
              this.$message.info('您的验证码已过期，请重新提交申请')
              localStorage.removeItem('code')
              // 延迟弹出弹窗
              setTimeout(() => {
                this.dialogVisible = true
              },2000)
            }
          }).catch((e) => {
            console.log(e)
            // 取消输入
            // 判断关闭方式
            if(e === 'cancel'){
              this.dialogVisible = true
            }
          });
        }

      }
    },
    // 提交申请
    submitPoc(Form){
      // let that = this
      this.$refs[Form].validate(async (valid) => {
        if (valid) {
          // 磐石接受短信人员手机号
          this.rzpxForm.phone = '18678391910'
          // console.log(this.rzpxForm)
          let result = await postReg(this.rzpxForm);
          if (result.data == 1) {
            /* 成功 */
            this.$message({message: '提交成功！请留意手机来电。',type: 'success'})
            // 重置表单
            this.rzpxForm={company:'',tel:'',email:''}
            this.dialogVisible = false
          }else if(result.data == -1){
            /* 手机号重复 */
            this.$message({message: '此手机号已提交过认证申请，请勿重新提交',type: 'error'})
          }else{
            this.$message.error('提交失败!')
          }
        } else {
          // 验证不通过
          this.$message.error('请正确填写内容，*为必填项')
          return false;
        }
      });
    },
    // 表单关闭
    handleClose() {
      this.$confirm('确认关闭？')
        .then(() => {
          this.dialogVisible = false
          // 重置表单
          this.rzpxForm = {company:'',tel:''}
        })
        .catch(() => {});
    },
    handleRegClose(){
      this.$confirm('确认关闭？')
        .then(() => {
          this.dialogRegVisible = false
          // 重置表单
          this.subForm = {content:[],company:'',region:'',address:'',userInfoList:[{userName:'',phone:''}],name:'',}
        })
        .catch(() => {});
    }
  },
  computed:{
    window: function () {
      // `this` 指向 vm 实例
      return window
    }
  },
  filters:{
    // 事件过滤器,只返回日期,不返回时间
    timeFilter(value){
      return value.split(' ')[0]
    }
  }

}
</script>

<style scoped>
.fl{float:left;}
.fr{float:right;}
.clearfix{clear:both;}
.picbox{width: 100%;}
.picbox img{width: 100%;}
.IntroductionTitle{border-left: 6px solid #e40f0f;line-height: 50px;font-size: 32px;padding-left: 30px;margin-top: 30px;}
.cc{background: #fff;padding-bottom: 30px;}
.cw{background: #f5f5f5;padding-bottom: 30px;padding-top: 30px;}
.sta{margin-top: 20px;}
.sta p{font-size: 16px;color: #888;line-height: 28px;text-align: justify;text-indent: 2em;width: 100%;}
.spt{font-size: 16px;color: #888;line-height: 24px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap; text-align: justify;width: 100%;}
.spa .fl{width: 19.5%;margin-right: 0.5%;box-sizing:border-box;border:1px solid #ccc;margin-bottom: 30px;}
.spa .fl:nth-child(5n){margin-right: 0;}
/* .videoBox{position: relative;width: 100%;height: 170px;}
.videoBox video{display: block;max-width: 100%;max-height: 100%;position: absolute;top: 0;bottom: 0;left: 0;right: 0;} */
.videoBox{width: 100%;position: relative;}
.cover{position: absolute;top: 0;left: 0;width: 100%;height: 100%;z-index: 10;cursor: pointer;}
.cover img{width: 100%;}
.videoBox video{display: block;width: 100%;}
.sptimer{font-size: 14px;color: #999;line-height: 22px;text-align: right;width: 100%;}
.spcontent{font-size: 14px; color: rgb(165, 165, 165);margin-top: 8px;line-height: 20px;height: 40px;overflow: hidden; text-align: justify;width: 100%;}
.pbox{padding: 10px;background: #fff;border-top: 1px solid #ccc;}
.bww{background: #fff;padding: 40px 20px;}
>>> .rzpx .el-select{width: 100%;}
>>> .rzpx .el-form-item__label{font-size:16px;}
>>> .rzpx .el-input__inner{border-radius: 20px;}
>>> .rzpxa .el-input__inner{border-radius: 20px;}
>>> .rzpx .el-textarea__inner{border-radius: 20px !important;}
>>> .rzpxa .el-textarea__inner{border-radius: 20px !important;}
.sub{width: 300px;border-radius: 30px;font-size: 18px;display: block;margin: 30px auto 0px;}
>>> .rzpx .el-col{margin-bottom: 15px;}
>>> .rzpxa .el-col{margin-bottom: 10px;}
/* >>> .rzpx .el-form-item{margin-bottom: 0;} */
>>> .rzpx .el-form-item__label{font-size: 14px;}
>>> .rzpxa .el-form-item__label{font-size: 14px;}
>>> .dialog_foot .el-button{border-radius: 20px;width: 300px;display: block;margin: 20px auto 0px;}
>>> .dialog_foot .el-button span{font-size: 16px;}
>>> .conte .el-form-item__label{font-size: 16px;font-weight: bold;text-align: left;}
>>> .contea .el-form-item{margin-bottom: 15px;}
.person{}
.person .fl:first-child{width: calc(50% - 5px);margin-right: 10px;}
.person .fl:nth-child(2){width: calc(50% - 43px);}
.person .fl:nth-child(3){width: 38px;}
.tit{font-size: 16px;font-weight: bold;}
>>> .person .el-form-item{margin-bottom: 20px;}
.covera img{width: 100%;}
.covera{background: #fff;}
.flc{border: none !important;}
.flc .pbox{background: #f5f5f5;border-top: none;}
.flc .cover{cursor: default;}
.flimg{position: absolute;width: 50px;top:calc(50% - 5px);left: calc(50% - 25px);z-index: 20;}

.flc{position: relative;}

/* .el-checkbox__label{font-size: 16px;} */
/* @media screen and (min-width: 1100px){
  .videoBox{height: 180px;}
}
@media screen and (min-width: 1200px){
  .videoBox{height: 200px;}
}
@media screen and (min-width: 1300px){
  .videoBox{height: 210px;}
}
@media screen and (min-width: 1400px){
  .videoBox{height: 220px;}
}
@media screen and (min-width: 1500px){
  .videoBox{height: 240px;}
}
@media screen and (min-width: 1600px){
  .videoBox{height: 260px;}
}
@media screen and (min-width: 1700px){
  .videoBox{height: 280px;}
}
@media screen and (min-width: 1800px){
  .videoBox{height: 290px;}
} */
</style>